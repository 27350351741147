import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SEO from '../../components/seo'
import { Layout } from '../../components/Layout'
import { theme } from '../../components/Layout/theme'
import { PageHeader } from '../../components/Header/PageHeader'
import { H1, SubTitle, H5 } from '../../components/Title'
import { Container, Column, Row } from '../../components/Grid'
import { Bold, Paragraph } from '../../components/Text'
import styled from '@emotion/styled'
import { ButtonLink } from '../../components/Button'

const ButtonLinkWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const EngelenCirkelPage = () => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "soul.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pageContent: contentfulSection(slug: { eq: "engelen-cirkel" }) {
        content {
          json
        }
      }
    }
  `)

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
  }

  const pageContent = documentToReactComponents(
    data.pageContent.content.json,
    richTextOptions,
  )

  return (
    <Layout>
      <SEO title="Engelen Cirkel" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.darkPink}
          style={{ marginBottom: '11px' }}>
          Engelen Cirkel
        </H1>
      </PageHeader>

      <Container>
        <Row style={{ paddingTop: '60px', paddingBottom: '40px' }}>
          <Column display={{ md: 10, xs: 10 }} offset={{ md: 1, xs: 1 }}>
            {pageContent}
          </Column>
        </Row>
      </Container>
      <div
        style={{
          backgroundColor: '#FBF4F4',
        }}>
        <Container>
          <Row>
            <Column
              display={{ md: 10 }}
              offset={{ md: 1 }}
              style={{ padding: '30px 0' }}>
              <ButtonLinkWrapper>
                <ButtonLink wide large to="/maak-een-afspraak/?engelen-cirkel">
                  Schrijf je nu in
                </ButtonLink>
              </ButtonLinkWrapper>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default EngelenCirkelPage
